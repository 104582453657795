<template>
  <div   @click="dropdown = true" :style="!SM ? 'width: 250px' : null" :class="SM ? 'mr-n1 mt-2' : null">

    <v-menu  :value="dropdown"
             @input="dropdown = $event"
             :close-on-content-click="false"
             :close-on-click="!serverSearch"
             offset-y
             z-index="9999999999999999999999999"
    >
      <template v-slot:activator="{}">
        <ws-text-field
            v-model="filters.search"
            @input="handleSearch"
            :placeholder="$t('SearchAndFilters')"
            @click="dropdown = true"
            @focus="focused = true;"
            @blur="focused = false"
            clearable
            icon="mdi-magnify"
        />
      </template>
      <v-sheet :min-width="!SM ? 400 : null"  class="pa-6" >

        <div class="d-flex justify-space-between align-center mb-4">
          <h4 >
            <v-icon :color="wsACCENT">mdi-tune-vertical</v-icon>
            {{ $t('Filters') }}
          </h4>
          <v-btn @click="dropdown = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-simple-table :style="`border: 0.5px solid ${wsBACKGROUND}`" >
          <tbody>

          <tr v-for="item in items"
              style="background-color: transparent"
              :key="item.value"
          >
            <td :style="`border-color:  ${wsBACKGROUND}`">

              <ft-select
                  v-if="item.type === 'select' || item.type === 'select_multiple'"
                  v-model="filters[item.value]"
                  @input="handleSearch"
                  :multiple=" item.type === 'select_multiple'"
                  :searchable="item.items.length > 7"
                  :items="item.items">
                <template #default="{text}">
                  <v-sheet color="transparent" class="d-flex align-center justify-space-between">
                    <h5 v-if="item.type !== 'select_multiple'" :style="`color : ${filters[item.value] !== null && filters[item.value] !== undefined  ? '' : wsDARKLIGHT }`">
                      {{  item.text  }} : {{ text }}
                    </h5>
                    <h5 v-else :style="`color : ${ Array.isArray(filters[item.value])  && filters[item.value].length > 0  ? '' : wsDARKLIGHT }`">
                      {{  item.text  }} : {{ text }}
                    </h5>

                    <div class="d-flex align-center">
                      <v-btn
                          v-if="filters[item.value] !== null && filters[item.value] !== undefined"
                          @click.stop="filters[item.value] = null"
                          icon small>
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>

                      <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
                    </div>

                  </v-sheet>
                </template>

              </ft-select>
              <v-sheet  v-if="item.type === 'text' || item.type === 'text_number'"
                  color="transparent" class="d-flex align-center ">

                <h5 class="text-no-wrap" :style="`color : ${wsDARKLIGHT }`">
                  {{  item.text  }}:
                </h5>

                <div class="d-flex align-center" style="width : 100%">
                  <ws-text-field
                      v-model="filters[item.value]"
                      width="100%"
                      color="red"
                      solo flat
                      @input="$emit('input',filters)"
                      :number="item.type === 'text_number'"
                      :placeholder="item.placeholder || $t('EnterValue')"
                  />
                </div>
              </v-sheet>

            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <div v-if="serverSearch" class="d-flex justify-end mt-5">
          <ws-button :disabled="Object.keys(buffer).length === 0" @click="resetSearch" text label="ResetSearch" :color="wsACCENT" class="mr-2" />
          <ws-button :disabled="valuesAreEqual" @click="search" label="Search" />
        </div>

      </v-sheet>
    </v-menu>


  </div>

</template>

<script>
export default {
  name: "wsSearchFilters",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    items : {
      type : Array,
      default() { return [] }
    },
    serverSearch : {
      type : Boolean,
      default : false
    },
    serverSearchAjax :  {
      type : Function,
    }
  },
  watch : {
    value() {
      if ( this.value !== this.filters ) {
        this.filters = JSON.parse(JSON.stringify(this.value))
      }
    }
  },
  data() {
    return {
      focused : false,
      dropdown : false,
      filters : {},
      buffer : {}
    }
  },
  computed : {
    valuesAreEqual() {
      if ( !this.serverSearch ) {
        return false
      }
      if ( this.filters === {} && this.buffer === {}) {
        return true
      }
      return this.EQ(this.filters , this.buffer)
    }
  },
  methods : {
    search() {
      this.$emit('input',this.filters)
      this.buffer = this.COPY(this.filters)
      this.dropdown = false
    },
    handleSearch() {
      if ( this.serverSearch ) {
        return
      }
      this.$emit('input',this.COPY(this.filters))
    },
    resetSearch() {
      this.filters = {}
      this.$emit('input',this.COPY(this.filters))
      this.dropdown = false
    }
  },
  mounted() {
    if ( this.value !== this.filters ) {
      this.filters = JSON.parse(JSON.stringify(this.value))
    }
    if ( Object.keys(this.filters).length === 0 ) {
      this.items.forEach(item => {
        this.filters[item.value] = item.type === 'select_multiple' ? [] : null
      })
      this.filters = JSON.parse(JSON.stringify(this.filters))
    }
  }
}
</script>

<style scoped>

</style>